const USER_ROLE_SUNSOUL = 'Restricted Merchant';
const USER_ROLE_ADMIN = 'Admin';
const USER_ROLE_LICENSEE = 'Licensee Merchant';
const USER_ROLE_PERGOLAS = 'Pergola Dealer';
const USER_ROLE_SW_SALES = 'Merchant';

const default_role = {
    canSeeCustomerDetails: true,
    canSearchCustomerDetails: true,
    alwaysSendNotificationEmail: false,
    shouldCreateZohoTaskForOrder: false,
    canMarkDelivered: false,
    shouldSeeDealerFields: false,
    createOrdersUnderUserAccount: false,
    onlyShowSunSoulProducts: false, // Also replaces S&S product names
    onlyShowPergolas: false,
    showSunSoulProducts: false,
    filterCondition: false
}

const dealer_defaults = {
    canSeeCustomerDetails: false,
    canSearchCustomerDetails: false,
    alwaysSendNotificationEmail: true,
    shouldCreateZohoTaskForOrder: true,
    canMarkDelivered: false,
    shouldSeeDealerFields: true,
    createOrdersUnderUserAccount: false,
    onlyShowSunSoulProducts: false,
    onlyShowPergolas: false,
    showSunSoulProducts: false,
    filterCondition: [
        'New',
        'New IT - Wholesale',
        'New - IT Wholesale',
        'New - Wholesale',
        'New IT',
        'Sold',
        'Sold - IT',
        'Forward Order'
    ]
}

const permissions = {
    [USER_ROLE_SUNSOUL]: {
        ...dealer_defaults,
        onlyShowSunSoulProducts: true,
        showSunSoulProducts: true,
    },
    [USER_ROLE_LICENSEE]: {
        ...dealer_defaults,
        createOrdersUnderUserAccount: true,
    },
    [USER_ROLE_PERGOLAS]: {
        ...dealer_defaults,
        onlyShowPergolas: true,
    },
    [USER_ROLE_ADMIN]: {
        canMarkDelivered: true,
        showSunSoulProducts: true
    },
    [USER_ROLE_SW_SALES]: {
        showSunSoulProducts: true,
        filterConditionExclude: [
            'QA Hold',
            'Unappraised',
            'Inventory Hold'
        ]
    }
}

const userCan = (userRole, permission) => {
    if ( typeof( permissions[userRole] ) === 'undefined' ) {
        return default_role[permission];
    }

    return typeof( permissions[userRole][permission] ) !== 'undefined' ? permissions[userRole][permission] : default_role[permission];
};

export const canSeeCustomerDetails = userRole => {
    return userCan(userRole, 'canSeeCustomerDetails');
};

export const canSearchCustomerDetails = userRole => {
    return userCan(userRole, 'canSearchCustomerDetails');
};

export const alwaysSendNotificationEmail = userRole => {
    return userCan(userRole, 'alwaysSendNotificationEmail');
};

export const shouldCreateZohoTaskForOrder = userRole => {
    return userCan(userRole, 'shouldCreateZohoTaskForOrder');
};

export const canMarkDelivered = userRole => {
    return userCan(userRole, 'canMarkDelivered');
};

export const shouldSeeDealerFields = userRole => {
    return userCan(userRole, 'shouldSeeDealerFields');
};

export const createOrdersUnderUserAccount = userRole => {
    return userCan(userRole, 'createOrdersUnderUserAccount');
};

export const onlyShowSunSoulProducts = userRole => {
    return userCan(userRole, 'onlyShowSunSoulProducts');
};

export const onlyShowPergolas = userRole => {
    return userCan(userRole, 'onlyShowPergolas');
};

export const showSunSoulProducts = userRole => {
    return userCan(userRole, 'showSunSoulProducts');
};
export const filterCondition = userRole => {
    return userCan(userRole, 'filterCondition');
};

export const filterConditionExclude = userRole => {
    return userCan(userRole, 'filterConditionExclude');
};